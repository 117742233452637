import React from 'react'
import WeCreatePageSeoContainer from '@containers/WeCreatePageSeoContainer'
import PrivacyPolicyPageContainer from '@containers/PrivacyPolicyPageContainer'

const IndexPage = () => (
  <>
    {/* SEO */}
    <WeCreatePageSeoContainer />
    <PrivacyPolicyPageContainer />
  </>
)

export default IndexPage
