import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ReactComponent as WeCreateLogoSvg } from '@static/svgs/weCreate.svg'
import { ReactComponent as SvgDownIcon } from '@static/svgs/down.svg'
import useTranslation from '@hooks/useTranslation'

const LogoWrapper = styled.div`
  ${props => props.theme.flex.centerAll}
  margin-right: 2%;
  ${props => props.theme.media.lessThan('320px')`
    transform: scale(0.8);
  `}
`

const GoUpBlock = styled.div`
  ${props => props.theme.flex.centerAll}
  font-size: 15px;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
`

const GoUpIcon = styled(SvgDownIcon)`
  transform: rotateZ(180deg);
  margin: 0 10px;
  & path {
    stroke: ${props => props.theme.color.codGray};
    stroke-width: 1.5px;
  }
`

const DisclamerPagesCommonContainer = ({ children }) => {
  const { labelStrings } = useTranslation()

  const goUpHandler = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }

  return (
    <>
      <LogoWrapper>
        <a href={process.env.WECREATE_HOST}>
          <WeCreateLogoSvg />
        </a>
      </LogoWrapper>
      {children}
      <GoUpBlock onClick={goUpHandler}>
        <GoUpIcon />
        {labelStrings.backToTop}
      </GoUpBlock>
    </>
  )
}

DisclamerPagesCommonContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
}

export default DisclamerPagesCommonContainer
