import React, { useEffect } from 'react'
import WeCreateLayout from '@layouts/WeCreateLayout'

import CookieConsent from '@common/CookieConsent'
import PageScrollHorizontalProgressBar from '@common/PageScrollHorizontalProgressBar'
import PrivacyPolicyContent from '@common/contents/privacyPolicy/PrivacyPolicyContent'

const PrivacyPolicyPageContainer = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [])

  return (
    <>
      <WeCreateLayout>
        <PageScrollHorizontalProgressBar />
        <PrivacyPolicyContent />
      </WeCreateLayout>

      {/* USE COOKIES */}
      <CookieConsent />
    </>
  )
}

export default PrivacyPolicyPageContainer
